/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import AOS from 'aos';
import Headroom from "headroom.js";

// Calculate
var timeout = false,
	timeoutDelay = 250;

function calculateSizes() {
	document.documentElement.style.setProperty('--vh', `${document.documentElement.clientHeight}px`);
	document.documentElement.style.setProperty('--sw', `${window.innerWidth - document.documentElement.clientWidth}px`);
	setNavigationSubHeight()
}

calculateSizes();

window.addEventListener('resize', function () {
	clearTimeout(timeout);
	timeout = setTimeout(calculateSizes, timeoutDelay);
});

// Animate On Scroll
AOS.init({
	once: true,
	easing: 'ease-in-sine'
});

// Lazy load video
var lazyVideos = document.querySelectorAll('.video.lazy');
if (lazyVideos.length) {
	if ("IntersectionObserver" in window) {
		var lazyVideoObserver = new IntersectionObserver(function (entries, observer) {
			entries.forEach(function (video) {
				if (video.isIntersecting) {
					for (var source in video.target.children) {
						var videoSource = video.target.children[source];
						if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
							videoSource.src = videoSource.dataset.src;
						}
					}

					video.target.load();
					video.target.classList.remove("lazy");
					lazyVideoObserver.unobserve(video.target);
				}
			});
		});

		lazyVideos.forEach(function (lazyVideo) {
			lazyVideoObserver.observe(lazyVideo);
		});
	}
}

// Dropdown
const foundDropdowns = document.querySelectorAll('.dropdown-checkbox');
addEventToDropdowns(foundDropdowns);
function addEventToDropdowns(dropdowns) {
	if (dropdowns.length) {
		var activeDropdown = null;
		var activeOption = null;

		var onDropdownChange = function (el) {
			if (activeDropdown !== null) {
				activeDropdown.checked = false;
			}
			if (el === activeDropdown) {
				activeDropdown = null;
			}
			else {
				activeDropdown = el;
			}
		};

		dropdowns.forEach(el => {
			el.addEventListener("change", async function (e) {
				onDropdownChange(el);
			});
		});

		document.addEventListener('click', e => {
			if (e.target.closest('.dropdown-checkbox') === null && e.target.closest('.dropdown') === null) {
				if (activeDropdown !== null) {
					onDropdownChange(activeDropdown);
				}
			}
		});
	}
}

const dropdownContents = document.querySelectorAll('.dropdown__content');
if (dropdownContents.length) {
	dropdownContents.forEach(el => {
		window.addEventListener("load", function () {
			setTimeout(function () { el.parentElement.style.height = el.offsetHeight + "px"; }, 200);
		});
		window.addEventListener("resize", function () {
			setTimeout(function () { el.parentElement.style.height = el.offsetHeight + "px"; }, 200);
		});
	});
}

// Add header headroom
var header = document.querySelector("header");
var headroom = new Headroom(header);
headroom.init();

// Jump to submitted form
document.querySelectorAll('.content-block.form-block').forEach(el => {
	var form = el.querySelector('form'),
		anchor = el.querySelector('.anchor'),
		button = el.querySelector('.button');

	if (form) {
		if (!anchor) {
			anchor = el.querySelector('.form').getAttribute('id');
		}
		else {
			anchor = anchor.getAttribute('id');
		}

		form.setAttribute('action', form.getAttribute('action') + '#' + anchor);

		form.addEventListener('submit', function () {
			button.classList.add('is-loading');
		});
	}
});

// Image slider
var imageSlider = document.querySelectorAll('.content-block.is-image-side, .content-block.image-slider');
if (imageSlider.length) {
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;

		imageSlider.forEach(el => {
			const columnBlock = el.querySelector('.columns.has-gutter-4');

			if (!columnBlock) { return; };

			const swiperItems = columnBlock.querySelectorAll('.column'),
				swiperContainer = document.createElement('div'),
				swiperButtonPrev = document.createElement('div'),
				swiperButtonNext = document.createElement('div');

			swiperContainer.setAttribute('class', 'swiper-container');
			columnBlock.setAttribute('class', 'swiper-wrapper');
			columnBlock.parentNode.insertBefore(swiperContainer, columnBlock);
			swiperContainer.appendChild(columnBlock);
			swiperButtonPrev.setAttribute('class', 'swiper-button-prev');
			swiperButtonNext.setAttribute('class', 'swiper-button-next');
			swiperContainer.appendChild(swiperButtonPrev);
			swiperContainer.appendChild(swiperButtonNext);
			el.classList.add('has-swiper');

			swiperItems.forEach(si => {
				si.classList.remove('column');
				si.classList.add('swiper-slide');
			});

			new Swiper(el.querySelector('.swiper-container'), {
				slidesPerView: 'auto',
				allowTouchMove: true,
				autoplay: {
					delay: 5000,
					duration: 2000
				},
				keyboard: {
					enabled: true
				},
				spaceBetween: 8,
				navigation: {
					nextEl: swiperButtonNext,
					prevEl: swiperButtonPrev
				}
			});
		});
	})();
}

// Home intro
var homeIntro = document.querySelectorAll('.home-intro');
if (homeIntro.length) {
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;

		homeIntro.forEach(el => {
			const swiperContainer = el.querySelector('.home-intro__slider-container');

			if (!swiperContainer) { return; };

			const swiperButtonPrev = document.createElement('div'),
				swiperButtonNext = document.createElement('div'),
				swiperPaginationProgress = document.createElement('div'),
				swiperPaginationCount = document.createElement('div')
				;

			swiperButtonPrev.setAttribute('class', 'swiper-button-prev');
			swiperButtonNext.setAttribute('class', 'swiper-button-next');
			swiperPaginationProgress.setAttribute('class', 'swiper-pagination-progress');
			swiperPaginationCount.setAttribute('class', 'swiper-pagination-count');
			swiperContainer.appendChild(swiperButtonPrev);
			swiperContainer.appendChild(swiperButtonNext);
			swiperContainer.appendChild(swiperPaginationProgress);
			swiperContainer.appendChild(swiperPaginationCount);
			el.classList.add('has-swiper');

			swiperPaginationCount.innerText = '01';

			new Swiper(el.querySelector('.swiper-container'), {
				slidesPerView: 1,
				allowTouchMove: true,
				autoplay: {
					delay: 5000,
					duration: 2000
				},
				loop: true,
				keyboard: {
					enabled: true
				},
				spaceBetween: 20,
				navigation: {
					nextEl: swiperButtonNext,
					prevEl: swiperButtonPrev
				},
				pagination: {
					el: swiperPaginationProgress,
					type: "progressbar"
				},
				on: {
					slideChange: function (swiper) {
						var realIndex = (swiper.realIndex + 1);
						if (realIndex < 10) {
							realIndex = '0' + realIndex;
						}
						swiperPaginationCount.innerText = realIndex;
					}
				}
			});
		});
	})();
}

// Lightgallery
async function loadLightGallery() {
	await import('lightgallery.js');
	await import('lg-video.js');

	lightGallery(document.getElementsByTagName("body")[0], {
		selector: '[rel=modal]',
		download: false,
		thumbnail: false,
		fullScreen: true,
		actualSize: false,
		hash: false,
		autoplayFirstVideo: false,
		videoMaxWidth: '80%'
	});
}

var elementsWithModal = document.querySelectorAll('[rel=modal]');
if (elementsWithModal.length) {
	loadLightGallery();
}

// Set nav height
function setNavigationSubHeight() {
	var navWithSubs = document.querySelectorAll('.nav__item.has-sub');
	if (navWithSubs) {
		navWithSubs.forEach(el => {
			var ul = el.querySelector('ul');
			ul.style.height = 'auto';
			ul.style.height = ul.scrollHeight + "px";
		});
	}
}

// Close navigation on backdrop click or escape key
const navigationToggler = document.getElementById('navigation__toggler'), navigation = document.querySelector('.navigation'), navigationContainer = document.querySelector('.navigation');
if (navigationToggler && navigation && navigationContainer) {

	const toggle = checked => {
		navigationToggler.checked = checked;
		navigation.setAttribute('aria-expanded', checked);

		if (checked) {
			//focusLock.on(navigationContainer);
			document.documentElement.classList.add('has-no-overflow');
		} else {
			//focusLock.off(navigationContainer);
			document.documentElement.classList.remove('has-no-overflow');
		}
	};

	navigation.addEventListener('click', e => {
		if (!navigationContainer.contains(e.target)) {
			toggle(false);
		}
	});

	document.addEventListener('keydown', e => {
		if (e.keyCode === 27 && navigationToggler.checked) {
			toggle(false);
		}
	});

	// Keep aria-expanded in-sync with checked state
	navigationToggler.addEventListener('change', () => {
		toggle(navigationToggler.checked);
	});

	toggle(navigationToggler.checked);
}

// Animte call to action
var callToAction = document.querySelectorAll('.call-to-action');
if (callToAction.length) {
	(async () => {
		const gsap = (await import(/* webpackMode: "eager" */ './modules/gsap.js')).default;

		callToAction.forEach(el => {

			var image = el.querySelector('.parallax__image');
			var highlightedBlock = el.querySelector('.call-to-action__highlighted-block');

			var timeline = gsap.timeline({
				scrollTrigger: {
					trigger: el,
					start: "top bottom",
					end: "bottom top",
					scrub: true,
					//markers: true,
				}
			})
				.add('scene1')
				.fromTo(image, { y: "-60%" }, { y: 0 }, "scene1")
				.fromTo(highlightedBlock, { y: "30%" }, { y: "-30%" }, "scene1")
		});
	})();
}

// Animate world map
var worldMapBlocks = document.querySelectorAll('.world-map-block');
if (worldMapBlocks.length) {
	(async () => {
		const gsap = (await import(/* webpackMode: "eager" */ './modules/gsap.js')).default;

		worldMapBlocks.forEach(el => {
			var worldMapBackground = el.querySelector('.world-map-block__background');

			var updateWorldMapTransform = function (percentageToAdd) {
				var styles = window.getComputedStyle(worldMapBackground);
				var matrix = new WebKitCSSMatrix(styles.transform);
				var elementWidth = styles.width;
				var currentPercentage = matrix.m41 / parseInt(elementWidth) * 100;
				var newPercentage = currentPercentage - percentageToAdd;

				if (newPercentage <= -50) {
					newPercentage = 0;
				}

				worldMapBackground.style.transform = "translateX(" + (newPercentage + '%') + ")";
			}

			var interval = setInterval(function () {
				updateWorldMapTransform(0.005);
			}, 10);

			var timeline = gsap.timeline({
				scrollTrigger: {
					trigger: el,
					start: "top bottom",
					end: "bottom top",
					scrub: true,
					onUpdate: self => { updateWorldMapTransform(0.1); }
				}
			});
		});
	})();
}